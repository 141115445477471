/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
 export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
  }
  
  /**
   * @param {string} str
   * @returns {Boolean}
   */
  export function validUsername(str) {
    const valid_map = ["admin", "editor"];
    return valid_map.indexOf(str.trim()) >= 0;
  }
  
  //去除字符串中的所有空格和$nbsp
  export function validStEnpty(str) {
    //删除所有空格以及$nbsp
    const regStr = str.replace(/[&nbsp;]|\s+/g, "");
    return regStr;
  }
  //是否为正整数
  export function validPosInteger(str) {
    return /^[1-9]+$/.test(str);
  }
  //是否为正确的数字
  export function validNumber(str) {
    return /^-{0,1}\d+\.{0,1}\d*$/.test(str);
  }
  //是否为整数
  export function validInteger(str) {
    return /^-{0,1}\d+\d*$/.test(str);
  }
  //正确的手机号
  export function validPhone(str) {
    return /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/.test(str);
  }
  //正确的邮箱地址
  export function validEmail(str) {
    return /^\w+@[a-zA-Z0-9]{2,10}(?:\.[a-z]{2,4}){1,3}$/.test(str);
  }
  
  
  